import {Controller} from 'stimulus';
import $ from "jquery";
import {categories} from "../js/modules/categories";
import Compressor from "compressorjs";
import {api} from "../js/modules/api";
import flatpickr from "flatpickr";
import {Polish} from "flatpickr/dist/l10n/pl";

export default class extends Controller {
    static targets = [
        'category',
        'city',
        'location',
        'categoryDescription',
        'quantity',
        'description',
        'email',
        'phone',
        'addButton',
        'dateFrom',
        'dateTo',
        'validUntil',
        'timeFrom',
        'timeTo',
        'validUntilTime',
        'urgent',
        'companyName',
        'name',
    ];

    static values = {
        baseUrl: String,
        appBaseUrl: String,
        category: String,
        city: String,
        isofferpage: Number,
    };

    category = null;
    city = null;
    location = null;
    sending = false;

    connect() {
        $(this.cityTarget).on('focus', () => {
            this.initAutocomplateLocation();
        });
        this.prepareFields();
        $(this.addButtonTarget).on('click', this.onSubmit.bind(this));

        this.initData();
    }

    initData() {
        if (localStorage.getItem('offer-category')) {
            this.category = JSON.parse(localStorage.getItem('offer-category'));
            $(this.categoryTarget).val(this.category.name);
            localStorage.removeItem('offer-category');
        }
        if (localStorage.getItem('offer-city')) {
            this.location = JSON.parse(localStorage.getItem('offer-city'));
            $(this.cityTarget).val(this.location.city);
            localStorage.removeItem('offer-city');
        }
        if (localStorage.getItem('offer-quantity')) {
            $(this.quantityTarget).val(localStorage.getItem('offer-quantity'));
            localStorage.removeItem('offer-quantity');
        }
    }

    onTriggered() {
        const input = this.categoryTarget;
        const $input = $(input);
        $input.autoComplete('show');
    }

    onSelected(event, item) {
        this.category = item;

        const $clear = $('.category-input-box').find(".clearable__clear");

        $clear.show();

        $(this.categoryDescriptionTarget).html(this.category.description);
    }

    onDropDownShow(event) {
        const $dropDown = $(event.target).parent().find('> .dropdown-menu.show');
        $dropDown.scrollTop(0);
    }

    onClearAutocomplete() {
        $(this.categoryDescriptionTarget).html('');
        $(this.categoryTarget).val('');
        const $clear = $('.category-input-box').find(".clearable__clear");

        $clear.hide();
        this.category = null;
    }

    prepareFields() {
        const im = new Inputmask("+48 999 999 999");
        im.mask($(this.phoneTarget));

        flatpickr($(this.dateFromTarget), {
            weekNumbers: true,
            enableTime: false,
            minDate: "today",
            dateFormat: "d-m-Y",
            locale: Polish,
            allowInput: true,
        });

        flatpickr($(this.dateToTarget), {
            weekNumbers: true,
            enableTime: false,
            minDate: "today",
            dateFormat: "d-m-Y",
            locale: Polish,
            allowInput: true,
        });

        flatpickr($(this.validUntilTarget), {
            weekNumbers: true,
            enableTime: false,
            minDate: "today",
            dateFormat: "d-m-Y",
            locale: Polish,
            allowInput: true,
        });
    }

    onSubmit(e) {
        e.preventDefault();

        if (this.sending) {
            return;
        }

        $('.partner-add-offer-success').addClass('d-none');
        $('.partner-add-offer-error').addClass('d-none');

        if (!this.location) {
            $(this.cityTarget).val('');
        }
        const form = $('#infoForm');
        if (form[0] === undefined || (form[0].checkValidity() === false)) {
            $('html, body').animate({
                scrollTop: form.offset().top
            }, 1000);
        }
        if ($('#infoForm')[0].checkValidity()) {
            this.sending = true;
            $('.button-loader').removeClass('d-none');
            api.getApi()
                .post(this.baseUrlValue + '/wholesale-inquiries', {
                    json: {
                        category: $(this.categoryTarget).val(),
                        quantity: +$(this.quantityTarget).val(),
                        additionalInformation: $(this.descriptionTarget).val(),
                        city: this.location.city,
                        postalCode: this.location.postalCode,
                        location: $(this.locationTarget).val(),
                        dateFrom: $(this.dateFromTarget).val() + ' ' + $(this.timeFromTarget).val(),
                        dateTo: $(this.dateToTarget).val() + ' ' + $(this.timeFromTarget).val(),
                        validUntil: $(this.validUntilTarget).val() + ' ' + $(this.validUntilTimeTarget).val(),
                        urgent: $(this.urgentTarget).is(':checked'),
                        phone: $(this.phoneTarget).val() || undefined,
                        email: $(this.emailTarget).val(),
                        companyName: $(this.companyNameTarget).val(),
                        name: $(this.nameTarget).val(),
                    },
                })
                .then(async (response) => {
                    $('.button-loader').addClass('d-none');
                    $('#sent-success').removeClass('d-none');
                    $('#infoForm')[0].reset();
                })
                .catch(() => {
                })
                .finally(() => {
                    this.sending = false;
                });

            return;
        }

        !$('#infoForm')[0].checkValidity() && $('#infoForm').addClass('was-validated');
    }

    initAutocomplateLocation() {
        const $google = google;
        const options = {
            componentRestrictions: { country: "pl" },
            strictBounds: false,
            fields: ['address_component', 'geometry'],
            types: ['(cities)']
        };
        const autocomplete = new $google.maps.places.Autocomplete(this.cityTarget, options);

        $google.maps.event.addListener(autocomplete, 'place_changed', () => {
            this.location = this.prepareLocation(autocomplete.getPlace());
            this.city = this.prepareLocation(autocomplete.getPlace()).city;
        });
    }

    prepareLocation(place) {
        let location = {
            city: null,
            street: null,
            postalCode: null,
            administrativeArea: null,
            geometry: {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            }
        }

        let streetNumber = null;
        let street = null;

        place.address_components.forEach(row => {
            if (row.types.includes('locality') || row.types.includes('administrative_area_level_3')) {
                location.city = row.long_name;
            }
            if (row.types.includes('street_number')) {
                streetNumber = row.long_name;
            }
            if (row.types.includes('route')) {
                street = row.long_name;
            }
            if (row.types.includes('administrative_area_level_1')) {
                location.administrativeArea = row.long_name;
            }
            if (row.types.includes('postal_code')) {
                location.postalCode = row.long_name.length === 6 ? row.long_name : row.long_name + '-000';
            }
        });

        location.street = street + (streetNumber ? ' ' + streetNumber : '');

        return location;
    }
}
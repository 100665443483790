import {Controller} from 'stimulus';
import $ from "jquery";
import {categories} from "../js/modules/categories";
import Compressor from "compressorjs";
import {api} from "../js/modules/api";

export default class extends Controller {
    static targets = [
        'name',
        'phone',
        'email',
        'description',
        'send',
    ];

    static values = {
        baseUrl: String,
        appBaseUrl: String,
    };

    sending = false;

    connect() {
        $(this.sendTarget).on('click', this.onSubmit.bind(this));
    }

    onSubmit(e) {
        e.preventDefault();

        if (this.sending === true) {
            return;
        }

        const form = $('#contactForm');
        if (form[0] === undefined || (form[0].checkValidity() === false)) {
            $('html, body').animate({
                scrollTop: form.offset().top
            }, 1000);
        }

        if (form[0].checkValidity()) {
            this.sending = true;
            $('.button-loader').removeClass('d-none');
            api.getApi()
                .post(this.baseUrlValue + '/contact', {
                    json: {
                        name: $(this.nameTarget).val(),
                        email: $(this.emailTarget).val(),
                        phone: $(this.phoneTarget).val(),
                        description: $(this.descriptionTarget).val(),
                    },
                })
                .then(async (response) => {
                    form.removeClass('was-validated');
                    $('#sent-success').removeClass('d-none');
                    form[0].reset();

                })
                .catch(() => {

                })
                .finally(() => {
                    this.sending = false;
                    $('.button-loader').addClass('d-none');
                });

            return;
        }

        !form[0].checkValidity() && form.addClass('was-validated');
    }
}
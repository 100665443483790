/**
 * Front-end JavaScript
 *
 * The JavaScript code you place here will be processed by esbuild. The output
 * file will be created at `../theme/js/script.min.js` and enqueued in
 * `../theme/functions.php`.
 *
 * For esbuild documentation, please see:
 * https://esbuild.github.io/
 */

import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import EffectCarousel from './swiper-carousel/effect-carousel.esm.js';

// eslint-disable-next-line
if (document.querySelector('.swiper-cars')) {
	const swiper = new Swiper('.swiper-cars', {
		modules: [Autoplay, Navigation, Pagination, EffectCarousel],
		// specify "carousel" effect
		effect: 'carousel',
		// carousel effect parameters
		carouselEffect: {
			// opacity change per side slide
			opacityStep: 0.33,
			// scale change per side slide
			scaleStep: 0.2,
			// amount of side slides visible, can be 1, 2 or 3
			sideSlides: 1,
		},
		grabCursor: true,
		loop: true,
		loopAdditionalSlides: 1,
		slidesPerView: 'auto',
		navigation: {
			nextEl: '.swiper-button-next-cars',
			prevEl: '.swiper-button-prev-cars',
		},
		pagination: {
			el: '.swiper-pagination-cars',
		},

		autoplay: {
			delay: 3000,
		},
	});
}
if (document.querySelector('.swiper-opinions')) {
	const swiperOpinions = new Swiper('.swiper-opinions', {
		modules: [Autoplay, Navigation, Pagination],
		grabCursor: true,
		loop: true,
		slidesPerView: 1,
		spaceBetween: 20,
		breakpoints: {
			640: {
				slidesPerView: 1,
			},
			768: {
				slidesPerView: 2,
			},
			1024: {
				slidesPerView: 3,
			},
			1280: {
				slidesPerView: 3,
			},
		},
		navigation: {
			nextEl: '.swiper-button-next-opinion',
			prevEl: '.swiper-button-prev-opinion',
		},
		pagination: {
			el: '.swiper-pagination-opinion',
		},
		autoplay: {
			delay: 3000,
		},
	});
}
if (document.querySelector('.swiper-blog')) {
	const swiperBlog = new Swiper('.swiper-blog', {
		modules: [Autoplay, Navigation],
		grabCursor: true,
		loop: true,
		slidesPerView: 1,
		spaceBetween: 20,
		breakpoints: {
			640: {
				slidesPerView: 1,
			},
			768: {
				slidesPerView: 2,
			},
			1024: {
				slidesPerView: 3,
			},
			1280: {
				slidesPerView: 3,
			},
		},
		navigation: {
			nextEl: '.swiper-button-next-blog',
			prevEl: '.swiper-button-prev-blog',
		},
		autoplay: {
			delay: 3000,
		},
	});
}

if (document.querySelector('.swiper-blog-posts')) {
	const swiperBlog = new Swiper('.swiper-blog-posts', {
		modules: [Autoplay, Navigation],
		grabCursor: true,
		loop: true,
		slidesPerView: 1,
		spaceBetween: 20,
		breakpoints: {
			640: {
				slidesPerView: 1,
			},
			768: {
				slidesPerView: 2,
			},
			1024: {
				slidesPerView: 3,
			},
			1280: {
				slidesPerView: 3,
			},
		},
		navigation: {
			nextEl: '.swiper-button-next-blog-posts',
			prevEl: '.swiper-button-prev-blog-posts',
		},
		autoplay: {
			delay: 3000,
		},
	});
}

function hideShowMastHeadOnScroll() {
	const masthead = document.getElementById('masthead');
	if (!masthead) return;

	// Function to set the yPercent transform
	function setYPercent(percent) {
		masthead.style.transform = `translateY(${percent}%)`;
	}

	let currentScroll = window.pageYOffset;
	setYPercent(0);
	if (currentScroll > 0) {
		masthead.classList.add('masthead-scrolled');
	}

	let lastScroll = 0;
	window.addEventListener('scroll', () => {
		currentScroll = window.pageYOffset;
		const vh =
			Math.max(
				document.documentElement.clientHeight || 0,
				window.innerHeight || 0
			) / 100;

		// Adjust the masthead styles based on scroll position
		if (currentScroll == 0) {
			masthead.classList.remove('masthead-scrolled');
		} else {
			masthead.classList.add('masthead-scrolled');
		}

		// Show or hide the masthead based on scroll direction and position
		if (currentScroll <= 30 * vh) {
			setYPercent(0);
		} else if (currentScroll > lastScroll) {
			// Hide the masthead unless the user is hovering over it
			if (masthead.matches(':hover')) {
				return;
			}

			setYPercent(-100);
		} else {
			setYPercent(0);
		}

		lastScroll = currentScroll;
	});
}

function toggleFilterOnMobileCategoryPage() {
	const filterToggle = document.querySelector('.filter-toggle');
	const filter = document.querySelector('.filters');
	if (!filterToggle) return;
	if (!filter) return;

	filterToggle.addEventListener('click', () => {
		filter.dataset.open = filter.dataset.open == 'true' ? 'false' : 'true';
	});
}

document.addEventListener('DOMContentLoaded', function () {
	hideShowMastHeadOnScroll();
	toggleFilterOnMobileCategoryPage();
});

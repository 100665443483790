export default class Utils {
    static arrayEquals(a1, array) {
        if (!array) {
            return false;
        }

        if (a1.length !== array.length) {
            return false;
        }

        for (let i = 0, l = a1.length; i < l; i++) {
            if (a1[i] instanceof Array && array[i] instanceof Array) {
                if (!this[i].equals(array[i])) {
                    return false;
                }
            } else if (a1[i] != array[i]) {
                // Warning - two different object instances will never be equal: {x:20} != {x:20}
                return false;
            }
        }

        return true;
    }

    static arrayFilterNonReadableString(array) {
        return array.filter((v) => 'string' === typeof v && '' !== v);
    }

    static attachEvent($el, callback, event = 'click') {
        if ($el.addEventListener) {
            //console.debug('Using addEventListener().');
            $el.addEventListener(event, callback, false);
        } else if ($el.attachEvent) {
            //console.debug('Using attachEvent().');
            $el.attachEvent('on' + event, callback);
        }
    }

    /**
     * @param {Element} el
     * @param {boolean|ScrollIntoViewOptions|string} [params]
     * @see https://stackoverflow.com/questions/59805835/scrollintoview-works-in-console-but-not-not-when-in-in-the-site-code
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
     */
    static scrollTo(el, params = '') {
        //console.log('Scrolling to element:', el);
        setTimeout(() => {
            if ('string' === typeof params) {
                params = {
                    behavior: 'smooth',
                    block: 'nearest',
                };
            }

            try {
                el.scrollIntoView(params);
            } catch (error) {
                console.error('Error when trying to scroll to element:', el, error);

                if ('boolean' === typeof params) {
                    el.scrollIntoView();
                }
            }
        }, 4);
    }

    static formatMoney(number) {
        return parseFloat(number).toFixed(2).replace('.', ',');
    }

    static getUnit(unitCode) {
        const units = {'KILOGRAM': 'kg', 'PIECE': 'szt.', "LITER": 'l', "TON":'t'};

        return unitCode in units ? units[unitCode] : unitCode;
    }
}
import {Controller} from 'stimulus';
import $ from "jquery";
import {api} from "../js/modules/api";
import Utils from "../js/modules/utils";
import Inputmask from "inputmask";

require("flatpickr/dist/themes/light.css");

export default class extends Controller {
    static targets = [
        'carContainer',
        'carsContainer',
        'paginationPagesContainer',
        'carTemplate',
        'paginationPageTemplate',
        'sortBy',
    ];
    static values = {
        apiUrl: String,
        url: String,
    };

    loading = false;
    category = null;
    limit = 1;
    offset = 0;
    total = null;
    quantity = null;
    locationFilter = null;
    transmissionFilter = null;
    sortByPrice = false;
    insruance = null;

    connect() {
        this.loadCar();

        const params = new URLSearchParams(location.search.substring(1));
        const city = params.get("city");
        const returnCity = params.get("return_city") && params.get("return_city").length > 0 ? params.get("return_city") : city;
        const dateStart = params.get("date_from");
        const dateEnd = params.get("date_to");
        const timeStart = params.get("time_from");
        const timeEnd = params.get("time_to");
        const insurance = params.get("insurance");

        this.insruance = parseInt(insurance);
        $('.insurance-type').text(this.insruance === 1 ? 'Pełne ubezpieczenie' : 'Podstawowe ubezpieczenie')
        $('.cityLabel').text(city);
        $('.returnCityLabel').text(returnCity ?? city);
        $('.dateFromLabel').text(dateStart + ' ' + timeStart);
        $('.dateToLabel').text(dateEnd + ' ' + timeEnd);

        const imPhone = new Inputmask("+99 999 999 999");
        const imPostalCode = new Inputmask("99-999");
        imPhone.mask($('.phone'));
        imPostalCode.mask($('.postalCode'));

        $('.back-to-results').on('click', (event) => {
            event.preventDefault();
            const params = new URLSearchParams(location.search.substring(1));
            params.delete('insurance');
            window.location.href = '/car?' + params.toString();
        });

        $('.order').on('click', (event) => {
            event.preventDefault();
            this.order();
        });

        $('.company-controls').attr('disabled', true);

        $('.car-company-type').on('change', function () {
            console.log($(this).val() === "E")
            if ($(this).val() === "E") {
                $('.company-controls').removeClass('d-none');
                $('.company-controls').attr('disabled', false);
            } else {
                $('.company-controls').addClass('d-none');
                $('.company-controls').attr('disabled', true);
            }
        });
    }

    order() {
        if (this.loading) {
            return;
        }
        const form = $('.order-form');
        form.addClass('was-validated');
        if (form[0] === undefined || (form[0].checkValidity() === false)) {
            $('html, body').animate({
                scrollTop: form.offset().top
            }, 1000);
            return;
        }

        this.loading = true;
        $('.button-loader').removeClass('d-none');

        let data = Object.fromEntries(new FormData(form[0]));
        data['quantity'] = this.quantity;
        data['paymentType'] = 'cash';

        const params = new URLSearchParams(location.search.substring(1));
        const city = params.get("city");
        const returnCity = params.get("return_city") && params.get("return_city").length > 0 ? params.get("return_city") : city;
        const dateStart = params.get("date_from");
        const dateEnd = params.get("date_to");
        const timeStart = params.get("time_from");
        const timeEnd = params.get("time_to");
        const id = params.get("id");

        data['city'] = city;
        data['returnCity'] = returnCity;
        data['dateFrom'] = dateStart + ' ' + timeStart;
        data['dateTo'] = dateEnd + ' ' + timeEnd;
        data['gps'] = parseInt(params.get("gps"));
        data['childSeat0'] = parseInt(params.get("childSeat0"));
        data['childSeat9'] = parseInt(params.get("childSeat9"));
        data['childSeat18'] = parseInt(params.get("childSeat18"));

        let requestData = {
            withTransport: 1,
            addedAsAdmin: data['email'].includes('+admin'),
            supervisor: localStorage.getItem('s'),
            withInsurance: this.insruance,
            data: JSON.stringify(data),
        };

        gtag('event', 'conversion', {
            'send_to': 'reserve',
            'value': parseFloat($('.price').text()),
            'currency': 'PLN'
        });
        gtag('event', 'reserve', {
            'value': parseFloat($('.price').text()),
            'currency': 'PLN'
        });

        api.getApi()
            .post(this.apiUrlValue + '/service/' + id + '/buy', {
                json: requestData,
            })
            .then(async (response) => {
                const data = await response.json();
                const params = new URLSearchParams(location.search.substring(1));
                params.append('token', data['token']);
                window.location.href = '/order?' + params.toString();
            }).catch((error) => {
            console.error(error);
        }).finally(() => {
            $('.button-loader').addClass('d-none');
            this.loading = false;
        })
    }

    loadCar() {
        if (this.loading) {
            return;
        }

        this.loading = true;

        const params = new URLSearchParams(location.search.substring(1));
        const serviceId = params.get("order");
        const city = params.get("city");
        const dateStart = params.get("date_from");
        const dateEnd = params.get("date_to");
        const timeStart = params.get("time_from");
        const timeEnd = params.get("time_to");
        const id = params.get("id");

        let url = '/service';

        let searchParams = {
            limit: this.limit,
            id: id,
        };

        if (serviceId) {
            searchParams['id'] = serviceId;
        }

        if (dateStart && dateEnd) {
            searchParams['dateFrom'] = dateStart + ' ' + timeStart;
            searchParams['dateTo'] = dateEnd + ' ' + timeEnd;
        }


        this.updateQuantity(searchParams['dateFrom'], searchParams['dateTo']);

        api.getApi()
            .get(this.apiUrlValue + url, {
                searchParams: searchParams
            })
            .then(async (response) => {
                gtag('event', 'car-preview', {
                    'city': city
                });

                const data = await response.json();

                this.createCarElement(data.items[0]);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                this.loading = false;
            });
    }

    createCarElement(service) {
        if (!service.carRental) {
            return null
        }

        $('.name').text(service.name);
        $('.numberOfSeats').text(service.carRental.numberOfSeats);
        $('.transition').text(this.getTransmissionLabel(service.carRental.transmission));
        $('.doors').text(service.carRental.numberOfDoors);
        $('.fuel').text(this.getFuelLabel(service.carRental.fuel));
        $('.air-conditioning').text(service.carRental.airConditioning ? 'Tak' : 'Nie');

        let price = Utils.formatMoney(service.dayPrice * this.quantity * ((100 + service.vatRate) / 100));
        const priceWithInsurance = Utils.formatMoney((service.dayPrice + service.carRental.insuranceValue) * this.quantity * ((100 + service.vatRate) / 100));

        price = this.insruance ? priceWithInsurance : price

        $('.car-price').find('span').text(price);
        $('.priceWithInsurance').text(priceWithInsurance);
        $('.price').text(price);
        $('.price-days').text(this.quantity);
        $('.quantity').text(this.quantity);
        $('.ownContribution').text(this.insruance ? service.carRental.ownContributionWithInsurance : service.carRental.ownContribution);
        $('.deposit').text(this.insruance ? service.carRental.depositWithInsurance : service.carRental.deposit);
        if (service.carRental.fuelPolicy) {
            $('.fuel-limit').text(service.carRental.fuelPolicy);
        } else {
            $('.fuel-limit-box').addClass('d-none');
        }
        if (service.carRental.kilometerLimit) {
            $('.kilometers').text(service.carRental.kilometerLimit + ' kilometrów dziennie');
        } else {
            $('.kilometers').text('Bez limitu kilometrów');
        }
        if (service.promoted) {
            $('.promoted').removeClass('d-none');
        }
        if (service.image) {
            $('.car-image').attr('src', service.image.url);
        } else {
            $('.car-image').attr('src', '/images/car/default-car.png');
        }
        if (service.company.logoUrl) {
            $('.company-logo').attr('src', service.company.logoUrl);
        } else {
            $('.company-logo').addClass('d-none');
        }

        if (service.additionalBenefit) {
            $('.car-benefit').removeClass('d-none');
            $('.car-benefit').text(this.additionalBenefitText(service.additionalBenefit));
        }

        if (service.branch.rentalConditions) {
            $('.rent-info').removeClass('d-none');
            $('.rent-info').attr('data-target', '#rental-conditions-' + service.id);
            $('.rentalConditionsModal').attr('id', 'rental-conditions-' + service.id);
            $('.rentalConditionsModal').find('.modal-body').html(service.branch.rentalConditions);
        }

        $('.company-street').text(service.branch.address.address);
        $('.company-name').text(service.company.name + ' - ' + service.branch.name);
        const description = service.availableWithTransport ? service.availableWithTransportDescription : service.availableWithoutTransportDescription;
        $('.additional-information').html(description);
    }

    getFuelLabel(fuel) {
        const types = {
            GAS: 'Benzyna',
            DIESEL: 'Diesel',
            GAS_LPG: 'Benzyna + LPG',
            HYBRID: 'Hybryda',
            ELECTRIC: 'Elektryczny'
        }

        return types[fuel];
    }

    getTransmissionLabel(transmission) {
        const types = {
            MANUAL: 'Manualna',
            AUTOMATIC: 'Automatyczna'
        }

        return types[transmission];
    }

    updateQuantity(dateFrom, dateTo) {
        if (!dateFrom || !dateTo) {
            this.quantity = 1;
            return;
        }
        const [dpf, tpf] = dateFrom.split(' ');
        const [df, mf, yf] = dpf.split('-').map(Number);
        const [hf, mif] = tpf.split(':').map(Number);
        dateFrom = new Date(yf, mf - 1, df, hf, mif);
        const [dpt, tpt] = dateTo.split(' ');
        const [dt, mt, yt] = dpt.split('-').map(Number);
        const [ht, mit] = tpt.split(':').map(Number);
        dateTo = new Date(yt, mt - 1, dt, ht, mit);

        const datesDiff = dateTo - dateFrom;

        let hours = Math.ceil(Math.round(Math.abs(datesDiff)) / 36e5);
        let days = Math.round(hours / 24);

        if (hours - days * 24 >= 2) {
            days += 1;
        }

        this.quantity = days;
    }
}

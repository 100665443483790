import {Controller} from 'stimulus';
import $ from "jquery";
import flatpickr from "flatpickr";
import {Polish} from "flatpickr/dist/l10n/pl";
import {api} from "../js/modules/api";
import Utils from "../js/modules/utils";

require("flatpickr/dist/themes/light.css");

export default class extends Controller {
    static targets = [
        'loadingContainer',
        'resultsContainer',
        'errorContainer',
        'carsContainer',
        'paginationPagesContainer',
        'resultsCount',
        'searchAgain',
        'carTemplate',
        'paginationPageTemplate',
        'clearFilters',
        'sortBy',
        'sortBy2',
    ];
    static values = {
        apiUrl: String,
        url: String,
    };

    loading = false;
    category = null;
    defaultCategory = 212;
    limit = 6;
    offset = 0;
    total = null;
    quantity = null;
    companyFilter = [];
    locationFilter = null;
    transmissionFilter = null;
    sortByPrice = true;

    connect() {
        this.loadResults(true, 0);
        $(this.searchAgainTarget).on('click', () => {
            this.loadResults(true, 0);
        });
        $('.pagination-page').on('click', (event) => {
            event.preventDefault();

            if ($(event.currentTarget).attr('aria-checked') === 'true') {
                return;
            }

            const page = $(event.target).data('page');
            this.offset = page * this.limit;
            this.updatePagination();
            this.loadResults(true, this.offset);
        });
        $('.pagination-next').on('click', (event) => {
            event.preventDefault();
            if ($(event.currentTarget).attr('disabled') === 'disabled') {
                return;
            }
            this.offset = this.offset + this.limit;
            this.updatePagination();
            this.loadResults(true, this.offset);
        });
        $('.pagination-prev').on('click', (event) => {
            event.preventDefault();
            if ($(event.currentTarget).attr('disabled') === 'disabled') {
                return;
            }
            this.offset = this.offset - this.limit;
            this.updatePagination();
            this.loadResults(true, this.offset);
        });

        $('input[name="category-filter"]').on('click', (event) => {
            if ($(event.currentTarget).data('checked')) {
                $(event.currentTarget).prop('checked', false);
                $(event.currentTarget).trigger("change");
            }
        });

        $('input[name="category-filter"]').on('change', (event) => {
            this.category = $(event.currentTarget).is(':checked') ? $(event.currentTarget).data('id') : null;
            this.loadResults(true, 0);
            $(event.currentTarget).data('checked', $(event.currentTarget).is(':checked') ? 1 : 0);
        });

        $('input[name="location-filter"]').on('click', (event) => {
            if ($(event.currentTarget).data('checked')) {
                $(event.currentTarget).prop('checked', false);
                $(event.currentTarget).trigger("change");
            }
        });

        $('input[name="location-filter"]').on('change', (event) => {
            this.locationFilter = $(event.currentTarget).is(':checked') ? $(event.currentTarget).data('id') : null;
            this.loadResults(true, 0);
            $(event.currentTarget).data('checked', $(event.currentTarget).is(':checked') ? 1 : 0);
        });

        $('input[name="transmission-filter"]').on('click', (event) => {
            if ($(event.currentTarget).data('checked')) {
                $(event.currentTarget).prop('checked', false);
                $(event.currentTarget).trigger("change");
            }
        });

        $('input[name="transmission-filter"]').on('change', (event) => {
            this.transmissionFilter = $(event.currentTarget).data('id');
            this.loadResults(true, 0);
            $(event.currentTarget).data('checked', $(event.currentTarget).is(':checked') ? 1 : 0);
        });

        $(this.clearFiltersTarget).on('click', () => {
            $('input[name="category-filter"]').prop('checked', false);
            $('input[name="location-filter"]').prop('checked', false);
            $('input[name="transmission-filter"]').prop('checked', false);
            this.companyFilter = [];
            this.sortByPrice = false;
            this.category = null;
            $('.sort-by-label').text(this.sortByPrice ? 'cena' : 'polecane');
            this.loadResults(true, 0);
        });

        $(this.sortByTarget).on('click', () => {
            this.sortByPrice = !this.sortByPrice;
            $('.sort-by-label').text(this.sortByPrice ? 'cena' : 'polecane');
            this.loadResults(true, 0);
        });

        $(this.sortBy2Target).on('click', () => {
            this.sortByPrice = !this.sortByPrice;
            $('.sort-by-label').text(this.sortByPrice ? 'cena' : 'polecane');
            this.loadResults(true, 0);
        });

        this.createRentalCompanyFilter();
    }

    updatePagination() {
        $('.pagination-page').attr('aria-checked', 'false');
        $('.pagination-next').attr('disabled', 'disabled');
        $('.pagination-prev').attr('disabled', 'disabled');
        if (this.total && this.total > (this.offset + this.limit)) {
            $('.pagination-next').removeAttr('disabled');
        }

        if (this.offset >= this.limit) {
            $('.pagination-prev').removeAttr('disabled');
        }

        const page = Math.ceil(this.offset / this.limit) + 1;

        $('.pagination-page[data-page="'+page+'"]').attr('aria-checked', 'true');
    }

    loadResults(generatePagination, offset) {
        if (this.loading) {
            return;
        }

        if (generatePagination){
            $(this.resultsContainerTarget).addClass('d-none');
            $(this.loadingContainerTarget).removeClass('d-none');
            $(this.errorCountTarget).addClass('d-none');
        }

        this.loading = true;

        const params = new URLSearchParams(location.search.substring(1));
        const serviceId = params.get("order");
        const city = params.get("city");
        const returnCity = params.get("return_city");
        const dateStart = params.get("date_from");
        const dateEnd = params.get("date_to");
        const timeStart = params.get("time_from");
        const timeEnd = params.get("time_to");
        const lat = params.get("lat");
        const lng = params.get("lng");
        const returnLat = params.get("returnLat");
        const returnLng = params.get("returnLng");
        const category = params.get("category") ?? this.category;

        let url = '/service';

        let searchParams = {
            limit: this.limit,
            offset: offset,
        };

        if (category) {
            searchParams['category'] = category;
        } else {
            searchParams['category'] = this.defaultCategory;
        }

        if (city) {
            searchParams['city'] = city;
        }

        if (returnCity) {
            searchParams['returnCity'] = returnCity;
        }

        if (lat && lng) {
            searchParams['lat'] = lat;
            searchParams['lng'] = lng;
        }

        if (returnLat && returnLng) {
            searchParams['returnLat'] = returnLat;
            searchParams['returnLng'] = returnLng;
        }

        if (serviceId) {
            searchParams['id'] = serviceId;
        }

        if (dateStart && dateEnd) {
            searchParams['dateFrom'] = dateStart + ' ' + timeStart;
            searchParams['dateTo'] = dateEnd + ' ' + timeEnd;
        }

        if (this.sortByPrice) {
            searchParams['sortByPrice'] = 'ASC';
        }

        if (this.locationFilter) {
            searchParams['location'] = this.locationFilter;
        }

        if (this.transmissionFilter) {
            searchParams['transmission'] = this.transmissionFilter;
        }

        if (this.companyFilter) {
            searchParams['companies'] = this.companyFilter.join(',');
        }

        this.updateQuantity(searchParams['dateFrom'], searchParams['dateTo']);

        $(this.carsContainerTarget).find('.car-box').remove();

        api.getApi()
            .get(this.apiUrlValue + url, {
                searchParams: searchParams
            })
            .then(async (response) => {
                gtag('event', 'search', {
                    'city': city
                });

                const data = await response.json();

                this.total = data.total;
                $(this.resultsCountTarget).text(data.total);

                if (generatePagination) {
                    this.createPaginationPages(data.total);
                }

                data.items.reverse().forEach((car) => {
                    const cheapest = data.cheapest;
                    const carElement = this.createCarElement(car, cheapest);
                    if (carElement) {
                        $(this.carsContainerTarget).prepend(carElement);
                    }
                });

                $(this.resultsContainerTarget).removeClass('d-none');
            })
            .catch((error) => {
                console.error(error);
                $(this.errorContainerTarget).removeClass('d-none');
            })
            .finally(() => {
                $(this.loadingContainerTarget).addClass('d-none');
                this.loading = false;
            });
    }

    createPaginationPages(total) {
        $('.pagination').addClass('d-none');
        $('.pagination-page-box').not('.paginationPageTemplate').remove();
        const template = $(this.paginationPageTemplateTarget).clone();

        const pages = Math.ceil(total / this.limit);

        if (pages <= 1) {
            return;
        }

        $('.pagination').removeClass('d-none');

        for (let i = 1; i <= Math.min(pages, 15); i++) {
            const page = template.clone();
            page.removeAttr('data-result-target');
            page.removeClass('d-none');
            page.removeClass('paginationPageTemplate');
            page.find('a').attr('data-page', i);
            page.find('span').text(i);
            $(this.paginationPagesContainerTarget).append(page);
        }

        this.updatePagination();
    }

    additionalBenefitText(additionalBenefit) {
        let benefit = '';
        switch (additionalBenefit) {
            case 'FREE_CANCELLATION':
                benefit = 'Bezpłatne odwołanie rezerwacji';
                break;
            case 'FREE_DRIVER':
                benefit = 'Dodatkowy kierowca gratis';
                break;
            case 'FREE_WINTER_TIRES':
                benefit = 'Opony zimowe wliczone w cenę';
                break;
            case 'NO_ADDITIONAL_FEES':
                benefit = 'Brak dodatkowych opłat';
                break;
            case 'NO_DEPOSIT':
                benefit = 'Brak depozytu';
                break;
            case 'CASH_DEPOSIT':
                benefit = 'Akceptujemy kaucję w gotówce';
                break;
            case 'IMMEDIATE_CONFIRMATION':
                benefit = 'Natychmiastowe potwierdzenie rezerwacji';
                break;
        }

        return benefit;
    }

    createCarElement(service, cheapest) {
        if (!service.carRental){
            return null
        }
        const template = $(this.carTemplateTarget).clone();

        if (cheapest[service.type.categoryId] === service.dayPrice) {
            template.find('.cheapest').removeClass('d-none');

            let text = 'Najtańsza oferta';
            switch (service.type.categoryId) {
                case 213:
                    text = 'Najtaniej w kategorii małe';
                    break;
                case 214:
                    text = 'Najtaniej w kategorii średnie';
                    break;
                case 215:
                    text = 'Najtaniej w kategorii duże';
                    break;
                case 216:
                    text = 'Najtaniej w kategorii kombi';
                    break;
                case 218:
                    text = 'Najtaniej w kategorii SUV';
                    break;
                case 219:
                    text = 'Najtaniej w kategorii premium';
                    break;
                case 220:
                    text = 'Najtaniej w kategorii luksusowe';
                    break;
                case 221:
                    text = 'Najtaniej w kategorii dostawcze';
                    break;
                case 222:
                    text = 'Najtaniej w kategorii van';
                    break;
            }
            template.find('.cheapest').text(text);
        }
        template.removeClass('d-none');
        template.find('.name').text(service.name);
        template.find('.name').text(service.name);

        const params = new URLSearchParams(location.search.substring(1));
        params.append('id', service.id);
        template.find('.name').attr('href', '/car?' + params.toString());
        template.find('.see-offer').attr('href', '/car?' + params.toString());
        template.find('.numberOfSeats').text(service.carRental.numberOfSeats);
        template.find('.transition').text(this.getTransmissionLabel(service.carRental.transmission));
        template.find('.doors').text(service.carRental.numberOfDoors);
        template.find('.fuel').text(this.getFuelLabel(service.carRental.fuel));
        template.find('.air-conditioning').text(service.carRental.airConditioning ? 'Tak' : 'Nie');

        template.find('.car-price').find('span').text(Utils.formatMoney(service.dayPrice * this.quantity * ((100 + service.vatRate) / 100)));
        template.find('.price-days').text(this.quantity);

        if (service.additionalBenefit) {
            template.find('.car-benefit').removeClass('d-none');
            template.find('.car-benefit').text(this.additionalBenefitText(service.additionalBenefit));
        }

        if (service.branch.rentalConditions) {
            template.find('.rent-info').removeClass('d-none');
            template.find('.rent-info').attr('data-target', '#rental-conditions-' + service.id);
            template.find('.rentalConditionsModal').attr('id', 'rental-conditions-' + service.id);
            template.find('.rentalConditionsModal').find('.modal-body').html(service.branch.rentalConditions);
        }

        if (service.carRental.fuelPolicy) {
            template.find('.fuel-limit').text(service.carRental.fuelPolicy);
        } else {
            template.find('.fuel-limit-box').addClass('d-none');
        }
        if (service.carRental.kilometerLimit) {
            template.find('.kilometers').text(service.carRental.kilometerLimit + ' kilometrów dziennie');
        } else {
            template.find('.kilometers').text('Bez limitu kilometrów');
        }
        if (service.image) {
            template.find('.car-image').attr('src', service.image.url);
        } else {
            template.find('.car-image').attr('src', '/images/car/default-car.png');
        }
        if (service.company.logoUrl) {
            template.find('.company-logo').attr('src', service.company.logoUrl);
        } else {
            template.find('.company-logo').addClass('d-none');
        }

        template.find('.see-offer').data('id', service.id);
        template.find('.company-street').text(service.branch.address.address);
        template.find('.company-name').text(service.company.name + ' - ' + service.branch.name);

        return template;
    }

    getFuelLabel(fuel) {
        const types = {
            GAS: 'Benzyna',
            DIESEL: 'Diesel',
            GAS_LPG: 'Benzyna + LPG',
            HYBRID: 'Hybryda',
            ELECTRIC: 'Elektryczny'
        }

        return types[fuel];
    }

    getTransmissionLabel(transmission) {
        const types = {
            MANUAL: 'Manualna',
            AUTOMATIC: 'Automatyczna'
        }

        return types[transmission];
    }

    updateQuantity(dateFrom, dateTo) {
        if (!dateFrom || !dateTo) {
            this.quantity = 1;
            return;
        }
        const [dpf, tpf] = dateFrom.split(' ');
        const [df, mf, yf] = dpf.split('-').map(Number);
        const [hf, mif] = tpf.split(':').map(Number);
        dateFrom = new Date(yf, mf - 1, df, hf, mif);
        const [dpt, tpt] = dateTo.split(' ');
        const [dt, mt, yt] = dpt.split('-').map(Number);
        const [ht, mit] = tpt.split(':').map(Number);
        dateTo = new Date(yt, mt - 1, dt, ht, mit);

        const datesDiff = dateTo - dateFrom;

        let hours = Math.ceil(Math.round(Math.abs(datesDiff)) / 36e5);
        let days = Math.round(hours / 24);

        if (hours - days * 24 >= 2) {
            days += 1;
        }

        this.quantity = days;
    }


    createRentalCompanyFilter() {
        api.getApi()
            .get(this.apiUrlValue + '/price-list/company?rental=true')
            .then(async (response) => {
                $('.company-filters').find('.company-filter').not('.company-filter-template').remove();
                const data = await response.json();
                const template = $('.company-filter-template');

                if (data.items.length === 0) {
                    return;
                }

                $('.company-filters').removeClass('d-none');
                data.items.forEach(company => {
                    const element = template.clone();
                    element.removeClass('d-none');
                    element.removeClass('car-company-template');
                    element.find('input').val(company.id);
                    element.find('span').text(company.name);
                    $('.company-filters-container').append(element);
                });

                $('.company-filter input').off('change');
                $('.company-filter input').change((event) => {
                    const companyCheckbox = $(event.target);

                    if (companyCheckbox.is(':checked')) {
                        this.companyFilter.push(companyCheckbox.val());
                    } else {
                        this.companyFilter = this.companyFilter.filter(item => item !== companyCheckbox.val());
                    }

                    this.loadResults(true, 0);
                });
            });
    }
}

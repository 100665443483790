/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/v2/app.css';

const $ = require('jquery/dist/jquery.slim.min');
global.$ = global.jQuery = $;

import 'popper.js';
import './js/v2/script';
import './js/lodash.min';
import 'lightslider/dist/js/lightslider.min'
import 'lightbox2/dist/js/lightbox.min'
import 'lightbox2/dist/css/lightbox.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'dropzone';
import 'swiper';
// import 'tailwindcss';
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

Dropzone.autoDiscover = false;

// start the Stimulus application
import './js/modules/bootstrap-select.min';
import './bootstrap';

const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
});

if (params.s) {
    localStorage.setItem('s', params.s);
}

Sentry.init({
    dsn: "https://877a3509c6b340c9b142d5b8aeb2b00c@o829484.ingest.sentry.io/4504372144439296",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

document.getElementsByClassName('.collapse-menu').onclick = function() {
    document.get('exp-panel').classList.toggle('expanded')
}

document.addEventListener('click', function(event) {
    if (event.target.matches('a[href^="#"]')) {
        event.preventDefault();
        const targetId = event.target.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({
                behavior: 'smooth'
            });
        }
    }
});
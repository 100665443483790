import {api} from "./api";

class Categories {
    /**
     * Flat list of all categories, mapped by category id.
     * @type {object}
     */
    mappedCategories = {};

    init(onFetched = null) {
        this.fetch()
            .then((data) => {
                // Map ALL system categories by id.
                const mappedCategories = {};
                const categories = data.categories;

                categories.forEach((category) => {
                    mappedCategories[category.id] = category;
                });
                this.mappedCategories = mappedCategories;

                // TODO: Dispatch event.

                if (onFetched) {
                    onFetched(categories, mappedCategories);
                }
            });
    }

    getMapped() {
        return this.mappedCategories;
    }

    async fetch() {
        const html = document.querySelector('html');
        let version = parseInt(html.getAttribute('data-c-v'), 10) || 0;
        const storedVersion = parseInt(localStorage.getItem('categoriesVersion'), 10) || -1;
        let categories = localStorage.getItem('categories');
        //
        // if (categories && storedVersion >= version) {
        //     try {
        //         categories = JSON.parse(categories);
        //
        //         return {
        //             categories,
        //             version,
        //         };
        //     } catch (error) {
        //         console.error('Error:', error);
        //         localStorage.removeItem('categoriesVersion');
        //         localStorage.removeItem('categories');
        //     }
        // }

        const data = await api.getApi()
            .get(html.getAttribute('data-api-url') + '/categories')
            .json();

        categories = data.categories;
        version = data.version;

        localStorage.setItem('categories', JSON.stringify(data.categories));
        localStorage.setItem('categoriesVersion', version);

        return {
            categories,
            version,
        };
    }
}

export var categories = new Categories();
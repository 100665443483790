import {Controller} from 'stimulus';
import $ from "jquery";
import flatpickr from "flatpickr";
import {Polish} from "flatpickr/dist/l10n/pl";

require("flatpickr/dist/themes/light.css");

export default class extends Controller {
    static targets = [
        'cityLabel',
        'returnCityLabel',
        'dateFromLabel',
        'dateToLabel',
        'returnCityOn',
        'city',
        'returnCity',
        'dateFrom',
        'dateTo',
        'timeFrom',
        'timeTo',
        'lat',
        'lng',
        'returnLat',
        'returnLng',
    ];
    static values = {
        apiUrl: String,
        url: String,
    };

    city = null;
    returnCity = null;
    location = null;
    returnLocation = null;
    dateToPicker = null;

    connect() {
        $(this.cityTarget).on('focus', () => {
            this.initAutocomplateLocation(this.cityTarget);
        });

        $(this.returnCityTarget).on('focus', () => {
            this.initAutocomplateLocation(this.returnCityTarget, true);
        });
        this.initFlatpickr();

        const params = new URLSearchParams(location.search.substring(1));
        const city = params.get("city");
        const returnCity = params.get("return_city") && params.get("return_city").length > 0 ? params.get("return_city") : city;
        const dateStart = params.get("date_from");
        const dateEnd = params.get("date_to");
        const timeStart = params.get("time_from");
        const timeEnd = params.get("time_to");
        const lat = params.get("lat");
        const lng = params.get("lng");
        const returnLat = params.get("returnLat");
        const returnLng = params.get("returnLng");

        this.city = city;
        this.returnCity = returnCity;

        $(this.cityLabelTarget).text(city);
        $(this.returnCityLabelTarget).text(returnCity ?? city);
        $(this.dateFromLabelTarget).text(dateStart + ' ' + timeStart);
        $(this.dateToLabelTarget).text(dateEnd + ' ' + timeEnd);

        $(this.cityTarget).val(city);
        $(this.returnCityTarget).val(returnCity);
        $(this.dateFromTarget).val(dateStart);
        $(this.dateToTarget).val(dateEnd);
        $(this.timeFromTarget).val(timeStart);
        $(this.timeToTarget).val(timeEnd);
        $(this.latTarget).val(lat);
        $(this.lngTarget).val(lng);
        $(this.returnLatTarget).val(returnLat);
        $(this.returnLngTarget).val(returnLng);

        $(this.returnCityOnTarget).on('change', (checked) => {
            if ($(this.returnCityOnTarget).is(':checked')) {
                $('.returnCityContainer').removeClass('d-none');
                $('.search-form').addClass('with-return-city');
                $(this.returnCityTarget).attr('required', true);
            } else {
                $('.returnCityContainer').addClass('d-none');
                $('.search-form').removeClass('with-return-city');
                $(this.returnCityTarget).attr('required', false);
            }
        });

        if (returnCity) {
            $(this.returnCityOnTarget).prop('checked', true);
            $(this.returnCityOnTarget).trigger('change');
        }

        $('.search-form').on('submit', (event) => {
            event.preventDefault();
            this.search();
        });
    }


    initAutocomplateLocation(element, returnCity = false) {
        if (returnCity && this.returnCityAutocomplete) {
            return;
        }

        if (!returnCity && this.cityAutocomplete) {
            return;
        }

        const $google = google;
        const options = {
            componentRestrictions: {country: "pl"},
            strictBounds: false,
            fields: ['address_component', 'geometry'],
            types: ['(cities)']
        };
        const autocomplete = new $google.maps.places.Autocomplete(element, options);

        $google.maps.event.addListener(autocomplete, 'place_changed', () => {
            if (returnCity) {
                this.returnLocation = this.prepareLocation(autocomplete.getPlace());
                this.returnCity = this.returnLocation.city;
                $(this.returnLatTarget).attr('value', this.returnLocation.geometry.lat);
                $(this.returnLngTarget).attr('value', this.returnLocation.geometry.lng);
                $(element).attr('placeholder', this.returnCity);
                $(element).attr('value', this.returnCity);
            } else {
                this.location = this.prepareLocation(autocomplete.getPlace());
                this.city = this.location.city;
                $(this.latTarget).attr('value', this.location.geometry.lat);
                $(this.lngTarget).attr('value', this.location.geometry.lng);
                $(element).attr('placeholder', this.city);
                $(element).attr('value', this.city);
            }
        });
    }

    prepareLocation(place) {
        let location = {
            city: place.name,
            street: null,
            postalCode: null,
            administrativeArea: null,
            geometry: {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            }
        }

        let streetNumber = null;
        let street = null;

        place.address_components.forEach(row => {
            if (row.types.includes('locality') || row.types.includes('administrative_area_level_3')) {
                location.city = row.long_name;
            }
            if (row.types.includes('street_number')) {
                streetNumber = row.long_name;
            }
            if (row.types.includes('route')) {
                street = row.long_name;
            }
            if (row.types.includes('administrative_area_level_1')) {
                location.administrativeArea = row.long_name;
            }
            if (row.types.includes('postal_code')) {
                location.postalCode = row.long_name.length === 6 ? row.long_name : row.long_name + '-000';
            }
        });

        location.street = street + (streetNumber ? ' ' + streetNumber : '');

        return location;
    }

    initFlatpickr() {
        flatpickr($(this.dateFromTarget), {
            weekNumbers: true,
            enableTime: false,
            minDate: "today",
            dateFormat: "d-m-Y",
            locale: Polish,
            allowInput: true,
            onChange: (rawdate, altdate, FPOBJ) => {
                this.dateFrom = rawdate;
                this.dateToPicker.set('minDate', altdate)
            }
        });
        this.dateToPicker = flatpickr($(this.dateToTarget), {
            weekNumbers: true,
            enableTime: false,
            minDate: "today",
            dateFormat: "d-m-Y",
            locale: Polish,
            allowInput: true,
            onChange: (rawdate, altdate, FPOBJ) => {
                this.dateTo = rawdate;

                console.log(this.dateFrom, this.city, this.dateTo);
            }
        });
    }

    search() {
        const form = $('.search-form');
        form.addClass('was-validated');
        if (form[0] === undefined || (form[0].checkValidity() === false)) {
            return;
        }

        const data = {
            city: this.city,
            return_city: this.returnCity,
            date_from: $(this.dateFromTarget).val(),
            date_to: $(this.dateToTarget).val(),
            time_from: $(this.timeFromTarget).val(),
            time_to: $(this.timeToTarget).val(),
            lat: $(this.latTarget).val(),
            lng: $(this.lngTarget).val(),
            returnLat: $(this.returnLatTarget).val(),
            returnLng: $(this.returnLngTarget).val(),
        };

        gtag('event', 'search', {
            'city': this.city
        });

        window.location = form.attr('action') + '?' + $.param(data);
    }
}

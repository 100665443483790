import {Controller} from 'stimulus';
import $ from "jquery";
import flatpickr from "flatpickr";
import {Polish} from "flatpickr/dist/l10n/pl";
import {api} from "../js/modules/api";
import Utils from "../js/modules/utils";

require("flatpickr/dist/themes/light.css");

export default class extends Controller {
    static targets = [
        'carContainer',
        'carsContainer',
        'paginationPagesContainer',
        'carTemplate',
        'paginationPageTemplate',
        'sortBy',
    ];
    static values = {
        apiUrl: String,
        url: String,
    };

    loading = false;
    category = null;
    limit = 1;
    offset = 0;
    total = null;
    quantity = null;
    locationFilter = null;
    transmissionFilter = null;
    sortByPrice = false;

    gps = 0;
    gpsPrice = 0;
    childSeat0 = 0;
    childSeat0Price = 0;
    childSeat9 = 0;
    childSeat9Price = 0;
    childSeat18 = 0;
    childSeat18Price = 0;

    connect() {
        this.loadCar();

        const params = new URLSearchParams(location.search.substring(1));
        const city = params.get("city");
        const returnCity = params.get("return_city") && params.get("return_city").length > 0 ? params.get("return_city") : city;
        const dateStart = params.get("date_from");
        const dateEnd = params.get("date_to");
        const timeStart = params.get("time_from");
        const timeEnd = params.get("time_to");
        $('.cityLabel').text(city);
        $('.returnCityLabel').text(returnCity ?? city);
        $('.dateFromLabel').text(dateStart + ' ' + timeStart);
        $('.dateToLabel').text(dateEnd + ' ' + timeEnd);

        $('.select-offer').on('click', (event) => {
            event.preventDefault();
            const params = new URLSearchParams(location.search.substring(1));
            params.append('insurance', $(event.target).data('insurance'));
            params.append('gps', this.gps.toString());
            params.append('childSeat0', this.childSeat0.toString());
            params.append('childSeat9', this.childSeat9.toString());
            params.append('childSeat18', this.childSeat18.toString());

            gtag('event', 'click-reserve', {
                'insurance': parseInt($(event.target).data('insurance'))
            });

            window.location.href = '/offer?' + params.toString();
        });

        $('.gps-minus').on('click', (event) => {
            this.gps = this.gps > 0 ? --this.gps : 0;
            $('.gps-count').text(this.gps);
            $('.gps-price').text(Utils.formatMoney((this.gps ?? 1) * this.gpsPrice));
        });

        $('.gps-plus').on('click', (event) => {
            this.gps = ++this.gps;
            $('.gps-count').text(this.gps);
            $('.gps-price').text(Utils.formatMoney((this.gps ?? 1) * this.gpsPrice));
        });

        $('.child-seat0-minus').on('click', (event) => {
            this.childSeat0 = this.childSeat0 > 0 ? --this.childSeat0 : 0;
            $('.child-seat0-count').text(this.childSeat0);
            $('.child-seat0-price').text(Utils.formatMoney((this.childSeat0 ?? 1) * this.childSeat0Price));
        });

        $('.child-seat0-plus').on('click', (event) => {
            this.childSeat0 = ++this.childSeat0;
            $('.child-seat0-count').text(this.childSeat0);
            $('.child-seat0-price').text(Utils.formatMoney((this.childSeat0 ?? 1) * this.childSeat0Price));
        });

        $('.child-seat9-minus').on('click', (event) => {
            this.childSeat9 = this.childSeat9 > 0 ? --this.childSeat9 : 0;
            $('.child-seat9-count').text(this.childSeat9);
            $('.child-seat9-price').text(Utils.formatMoney((this.childSeat9 ?? 1) * this.childSeat9Price));
        });

        $('.child-seat9-plus').on('click', (event) => {
            this.childSeat9 = ++this.childSeat9;
            $('.child-seat9-count').text(this.childSeat9);
            $('.child-seat9-price').text(Utils.formatMoney((this.childSeat9 ?? 1) * this.childSeat9Price));
        });

        $('.child-seat18-minus').on('click', (event) => {
            this.childSeat18 = this.childSeat18 > 0 ? --this.childSeat18 : 0;
            $('.child-seat18-count').text(this.childSeat18);
            $('.child-seat18-price').text(Utils.formatMoney((this.childSeat18 ?? 1) * this.childSeat18Price));
        });

        $('.child-seat18-plus').on('click', (event) => {
            this.childSeat18 = ++this.childSeat18;
            $('.child-seat18-count').text(this.childSeat18);
            $('.child-seat18-price').text(Utils.formatMoney((this.childSeat18 ?? 1) * this.childSeat18Price));
        });
    }

    loadCar() {
        if (this.loading) {
            return;
        }

        this.loading = true;

        const params = new URLSearchParams(location.search.substring(1));
        const serviceId = params.get("order");
        const city = params.get("city");
        const dateStart = params.get("date_from");
        const dateEnd = params.get("date_to");
        const timeStart = params.get("time_from");
        const timeEnd = params.get("time_to");
        const id = params.get("id");

        let url = '/service';

        let searchParams = {
            limit: this.limit,
            id: id,
        };

        if (serviceId) {
            searchParams['id'] = serviceId;
        }

        if (dateStart && dateEnd) {
            searchParams['dateFrom'] = dateStart + ' ' + timeStart;
            searchParams['dateTo'] = dateEnd + ' ' + timeEnd;
        }


        this.updateQuantity(searchParams['dateFrom'], searchParams['dateTo']);

        api.getApi()
            .get(this.apiUrlValue + url, {
                searchParams: searchParams
            })
            .then(async (response) => {
                gtag('event', 'car-preview', {
                    'city': city
                });

                const data = await response.json();

                this.createCarElement(data.items[0]);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                this.loading = false;
            });
    }

    createCarElement(service) {
        if (!service.carRental) {
            return null
        }

        gtag('event', 'show-offer', {
            'offer': service.id
        });

        $('.name').text(service.name);
        $('.numberOfSeats').text(service.carRental.numberOfSeats);
        $('.transition').text(this.getTransmissionLabel(service.carRental.transmission));
        $('.doors').text(service.carRental.numberOfDoors);
        $('.fuel').text(this.getFuelLabel(service.carRental.fuel));
        $('.air-conditioning').text(service.carRental.airConditioning ? 'Tak' : 'Nie');

        const price = Utils.formatMoney(service.dayPrice * this.quantity * ((100 + service.vatRate) / 100));
        const priceWithInsurance = Utils.formatMoney((service.dayPrice + service.carRental.insuranceValue) * this.quantity * ((100 + service.vatRate) / 100));
        $('.car-price').find('span').text(price);
        $('.priceWithInsurance').text(priceWithInsurance);
        $('.price').text(price);
        $('.avgPrice').text(Utils.formatMoney((service.dayPrice * this.quantity * ((100 + service.vatRate) / 100)) * 1.7));
        $('.price-days').text(this.quantity);
        $('.quantity').text(this.quantity);
        $('.ownContribution').text(service.carRental.ownContribution);
        $('.deposit').text(service.carRental.deposit);
        $('.depositWithInsurance').text(service.carRental.depositWithInsurance);
        $('.ownContributionWithInsurance').text(service.carRental.ownContributionWithInsurance);
        if (service.carRental.fuelPolicy) {
            $('.fuel-limit').text(service.carRental.fuelPolicy);
        } else {
            $('.fuel-limit-box').addClass('d-none');
        }
        if (service.carRental.kilometerLimit) {
            $('.kilometers').text(service.carRental.kilometerLimit + ' kilometrów dziennie');
        } else {
            $('.kilometers').text('Bez limitu kilometrów');
        }
        if (service.promoted) {
            $('.promoted').removeClass('d-none');
        }
        if (service.image) {
            $('.car-image').attr('src', service.image.url);
        } else {
            $('.car-image').attr('src', '/images/car/default-car.png');
        }
        if (service.company.logoUrl) {
            $('.company-logo').attr('src', service.company.logoUrl);
        } else {
            $('.company-logo').addClass('d-none');
        }

        if (service.additionalBenefit) {
            $('.car-benefit').removeClass('d-none');
            $('.car-benefit').text(this.additionalBenefitText(service.additionalBenefit));
        }

        if (service.branch.rentalConditions) {
            $('.rent-info').removeClass('d-none');
            $('.rent-info').attr('data-target', '#rental-conditions-' + service.id);
            $('.rentalConditionsModal').attr('id', 'rental-conditions-' + service.id);
            $('.rentalConditionsModal').find('.modal-body').html(service.branch.rentalConditions);
        }

        const cityAddress = `${service.branch.company.name}<br><br>${service.branch.address.address}<br>${service.branch.address.postalCode} ${service.branch.address.city}<br><br>${service.branch.phone}<br>${service.branch.email}`;
        $('.city-info').attr('data-target', '#city-' + service.id);
        $('.cityModal').attr('id', 'city-' + service.id);
        $('.cityModal').find('.modal-body').html(cityAddress);

        let returnCityAddress = `${service.returnBranch.company.name}<br><br>${service.returnBranch.address.address}<br>${service.returnBranch.address.postalCode} ${service.returnBranch.address.city}<br><br>${service.returnBranch.phone}<br>${service.returnBranch.email}`;
        ;

        $('.return-city-info').attr('data-target', '#return-city-' + service.id);
        $('.returnCityModal').attr('id', 'return-city-' + service.id);
        $('.returnCityModal').find('.modal-body').html(returnCityAddress);

        $('.company-street').text(service.branch.address.address);
        $('.company-name').text(service.company.name + ' - ' + service.branch.name);
        if (service.company.offerBenefits) {
            $('.additional-information').html(service.company.offerBenefits.replace(/(?:\r\n|\r|\n)/g, '<br>'));
        }

        if (service.company.gpsPrice) {
            this.gpsPrice = service.company.gpsPrice;
            $('.gps-price').text(Utils.formatMoney(service.company.gpsPrice));
            $('.gps-box').removeClass('d-none');
            $('.gps-unit').text(service.company.gpsPriceDay ? 'dzień' : 'wynajem');
        }
        if (service.company.childSeat0Price) {
            this.childSeat0Price = service.company.childSeat0Price;
            $('.child-seat0-price').text(Utils.formatMoney(service.company.childSeat0Price));
            $('.child-seat0-box').removeClass('d-none');
            $('.child-seat0-unit').text(service.company.childSeat0PriceDay ? 'dzień' : 'wynajem');
        }
        if (service.company.childSeat9Price) {
            this.childSeat9Price = service.company.childSeat9Price;
            $('.child-seat9-price').text(Utils.formatMoney(service.company.childSeat9Price));
            $('.child-seat9-box').removeClass('d-none');
            $('.child-seat9-unit').text(service.company.childSeat9PriceDay ? 'dzień' : 'wynajem');
        }
        if (service.company.childSeat18Price) {
            this.childSeat18Price = service.company.childSeat18Price;
            $('.child-seat18-price').text(Utils.formatMoney(service.company.childSeat18Price));
            $('.child-seat18-box').removeClass('d-none');
            $('.child-seat18-unit').text(service.company.childSeat18PriceDay ? 'dzień' : 'wynajem');
        }
    }

    getFuelLabel(fuel) {
        const types = {
            GAS: 'Benzyna',
            DIESEL: 'Diesel',
            GAS_LPG: 'Benzyna + LPG',
            HYBRID: 'Hybryda',
            ELECTRIC: 'Elektryczny'
        }

        return types[fuel];
    }

    additionalBenefitText(additionalBenefit) {
        let benefit = '';
        switch (additionalBenefit) {
            case 'FREE_CANCELLATION':
                benefit = 'Bezpłatne odwołanie rezerwacji';
                break;
            case 'FREE_DRIVER':
                benefit = 'Dodatkowy kierowca gratis';
                break;
            case 'FREE_WINTER_TIRES':
                benefit = 'Opony zimowe wliczone w cenę';
                break;
            case 'NO_ADDITIONAL_FEES':
                benefit = 'Brak dodatkowych opłat';
                break;
            case 'NO_DEPOSIT':
                benefit = 'Brak depozytu';
                break;
            case 'CASH_DEPOSIT':
                benefit = 'Akceptujemy kaucję w gotówce';
                break;
            case 'IMMEDIATE_CONFIRMATION':
                benefit = 'Natychmiastowe potwierdzenie rezerwacji';
                break;
        }

        return benefit;
    }

    getTransmissionLabel(transmission) {
        const types = {
            MANUAL: 'Manualna',
            AUTOMATIC: 'Automatyczna'
        }

        return types[transmission];
    }

    updateQuantity(dateFrom, dateTo) {
        if (!dateFrom || !dateTo) {
            this.quantity = 1;
            return;
        }
        const [dpf, tpf] = dateFrom.split(' ');
        const [df, mf, yf] = dpf.split('-').map(Number);
        const [hf, mif] = tpf.split(':').map(Number);
        dateFrom = new Date(yf, mf - 1, df, hf, mif);
        const [dpt, tpt] = dateTo.split(' ');
        const [dt, mt, yt] = dpt.split('-').map(Number);
        const [ht, mit] = tpt.split(':').map(Number);
        dateTo = new Date(yt, mt - 1, dt, ht, mit);

        const datesDiff = dateTo - dateFrom;

        let hours = Math.ceil(Math.round(Math.abs(datesDiff)) / 36e5);
        let days = Math.round(hours / 24);

        if (hours - days * 24 >= 2) {
            days += 1;
        }

        this.quantity = days;
    }


    createRentalCompanyFilter() {
        api.getApi()
            .get(this.apiUrlValue + '/price-list/company?rental=true')
            .then(async (response) => {
                $('.company-filters').find('.company-filter').not('.company-filter-template').remove();
                const data = await response.json();
                const template = $('.company-filter-template');

                if (data.items.length === 0) {
                    return;
                }

                $('.company-filters').removeClass('d-none');
                data.items.forEach(company => {
                    const element = template.clone();
                    element.removeClass('d-none');
                    element.removeClass('car-company-template');
                    element.find('input').val(company.id);
                    element.find('span').text(company.name);
                    $('.company-filters-container').append(element);
                });

                $('.company-filter input').off('change');
                $('.company-filter input').change((event) => {
                    const companyCheckbox = $(event.target);

                    if (companyCheckbox.is(':checked')) {
                        this.companyFilter.push(companyCheckbox.val());
                    } else {
                        this.companyFilter = this.companyFilter.filter(item => item !== companyCheckbox.val());
                    }

                    this.loadResults(true, 0);
                });
            });
    }
}

import ky from 'ky'

class Api {
    constructor() {
        this.api = ky.create({
            //prefixUrl: 'https://127.0.0.1/api',
            hooks: {
                beforeRequest: [
                    request => {
                        // Will result in CORS error if this header is not accepted by API.
                        request.headers.set('X-Requested-With', 'WM Landing-Page');
                        request.headers.set('Accept', 'application/json, text/plain, */*');
                        //request.headers.set('Authorization', `Bearer ${token}`);
                    }
                ]
            },
            timeout: 60000, // Default is 10 000 ms (10 seconds).
        });
    }

    getApi() {
        return this.api
    }
}

export var api = new Api()